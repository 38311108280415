//
// Google font - Poppins
//

@import url('https://fonts.googleapis.com/css2?family=Gantari:wght@300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
//
// Premium Font : HKGrotesk
//


// @font-face {
//     font-family: "hkgrotesk";
//     src: url("../../fonts/hkgrotesk-bold.eot");
//     src: local('hkgrotesk bold'), url("../../fonts/hkgrotesk-bold.woff") format("woff");
//     font-weight: 700;
// }

.fs-16px {
  font-size: 16px;
}

.text-long-height {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-long {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}