//
// _form-control.scss
//

.form-control {
  display: block;
  width: 100%;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
  appearance: none; // Fix appearance for date inputs in Safari

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @include border-radius($input-border-radius, 0);

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  // Customize the `:focus` state to imitate native WebKit styles.
  &:focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $input-focus-box-shadow;
    }
  }

}

.form-icon {
  position: relative;

  .form-control-icon {
    padding-left: $input-padding-x*3;
    position: relative;
  }

  i {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 18px;
    display: flex;
    align-items: center;
  }

  &.right {
    .form-control-icon {
      padding-right: $input-padding-x*3;
      padding-left: $input-padding-x;
      position: relative;
    }

    i {
      left: auto;
      right: 18px;
    }
  }
}

[data-layout-mode="dark"] {
  .form-select {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$gray-400}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>"));
  }
}

.file-uploader label {
  width: 100%;
}

.form-control {
  &:disabled {
    background-color: var(--vz-input-disabled-bg) !important;
  }
}

.w-fit {
  width: fit-content;
}